.Card {
  box-sizing: border-box;
  width: 112px;
  min-width: 112px;
  height: 112px;
  min-height: 112px;
  margin-right: 24px;
  padding: 12px;
  padding-bottom: 8px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 8px 12px -4px rgba(0, 0, 0, 0.15),
    0 0 12px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
  transition: opacity 0.4s ease-in;

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-bottom: 7px;
  }
  span {
    color: #202020;
    font-family: Assistant;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }
}
